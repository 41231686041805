
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: () =>
                import ('../views/MyLogin.vue'),
        },
        {
            path: '',
            component: () =>
                import ('../components/MainContainer.vue'),
            children: [
                // {
                //     path: '/noticias/:id?',
                //     name: 'Noticias',
                //     component: () =>
                //         import ('../views/MyNoticias.vue')
                // },
                // {
                //     path: '/categorias_noticias/:id?',
                //     name: 'Categorias Noticias',
                //     component: () =>
                //         import ('../views/CategoriasNoticias.vue')
                // },
                {
                    path: '/usuarios/:id?',
                    name: 'Usuarios',
                    component: () =>
                        import ('../views/MyUsuarios.vue')
                },
                {
                    path: '/adminusers/:id?',
                    name: 'Usuarios Administradores',
                    component: () =>
                        import ('../views/AdminUsers.vue')
                }, 
                {
                    path: '/sesiones/',
                    name: 'Sesiones',
                    component: () =>
                        import ('../views/MySesions.vue')
                },
                {
                    path: '/canales/:url?',
                    name: 'Canal',
                    component: () =>
                        import ('../views/MyCanales.vue')
                },
                {
                    path: '/videos_sin_auth',
                    name: 'VideosSinAuth',
                    component: () =>
                        import ('../views/VideosSinAuth.vue')
                },
                {
                    path: '/robot_nomadas',
                    name: 'robotNomadas',
                    component: () =>
                        import ('../views/RobotNomadasChat.vue')
                }

            ]
        }
    ]
});
