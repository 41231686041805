	export default [
		// {
		// 	url: "/noticias",
		// 	name: "Noticias",
		// 	icon: "rss_feed",
		// 	permission: 1
		// },
		// {
		// 	url: "/categorias_noticias",
		// 	name: "Categorias Noticias",
		// 	icon: "local_offer",
		// 	permission: 0
		// },
		{
			url: "/usuarios",
			name: "Usuarios",
			icon: "group",
			permission: 0
		},
		{
			url: "/adminusers",
			name: "Usuarios Administradores",
			icon: "group",
			permission: 0
		},
		{
			url: "/sesiones",
			name: "Sesiones",
			icon: "group",
			permission: 0
		},
		{
			url: "/canales",
			name: "Canales",
			icon: "group",
			permission: 0
		},
		{
			url: "/videos_sin_auth",
			name: "Videos sin autorizar",
			icon: "group",
			permission: 0
		},
		{
			url: "/robot_nomadas",
			name: "Mensajes Robot Nomadas",
			icon: "group",
			permission: 0
		}
	]