import Vuex from 'vuex'
import axios from 'axios';
import Vue from 'vue'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        user: null,
        isSidebarActive: false,
        themeColor: "#2962ff"
    },
    mutations: {
        login: (state,  user) => {
            localStorage.setItem('username', user.username);
            localStorage.setItem('adm_token', user.token);
            axios.defaults.headers.common['API-TOKEN'] = localStorage.getItem('adm_token') ? localStorage.getItem('adm_token') : "";
            state.user = user.username;
        },
        logout: (state) => {
            localStorage.removeItem('adm_token');
            localStorage.removeItem('username');
            state.user = null;
        },
        initialStore(state){
            if(localStorage.getItem('username'))
                state.user = localStorage.getItem('username');
        },
        IS_SIDEBAR_ACTIVE(state, value) {
              state.isSidebarActive = value;
        },  
      
    },
    actions: {
    },
    modules: {
    }
})
